
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// --- CONST START ---
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
// --- CONST END ---

Rails.start();
Turbolinks.start();
ActiveStorage.start();

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

const Shared = require('./shared.js');
window.atokyo3 = {
    shared: Shared
// --- START ---
// --- END ---
};

atokyo3.shared.load();
