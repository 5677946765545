class Shared {
    static load() {
        console.log('load');

        // 戻るボタンでデータ再取得
        $(window).on("popstate", function (event) {
            window.location.reload();
        })

        // 多重モーダルを許可
        $(document).on('hidden.bs.modal', () => {
            if($('.modal').is(':visible')) {
                $(document.body).addClass('modal-open');
            }
        });

        // スピナー
        $(document).on('click', '.ata-button-spinner', (event) => {
            atokyo3.shared.init_spinner($(event.currentTarget));
            return true;
        });

        // モーダル閉じるとスピナー終了
        $(document).on('hidden.bs.modal', '.modal', () => {
            atokyo3.shared.end_spinner();
        });

        // 画面遷移後にサイドメニューのJSが効かなくなる
        $(document).on('turbolinks:load', (event) => {
            $('.sidebar').sidebar();
            $('.aside-menu')['aside-menu']();
            console.log('turbolinks:load');
            $('[data-toggle="popover"]').popover();
        });

        // サイドバー開閉の維持
        $(document).on('turbolinks:before-render', (event) => {
            if (($(document.body)).hasClass("sidebar-minimized")) {
                $(event.originalEvent.data.newBody).addClass('brand-minimized sidebar-minimized');
            }
            else {
                $(event.originalEvent.data.newBody).removeClass('brand-minimized sidebar-minimized');
            }
        });

        // クリックイベント伝搬防止
        $(document).on('click', '.ata-stop-propagation', (event) => {
            event.stopPropagation();
            return true;
        });

        // div全体をリンクとする
        $(document).on('click', '.ata-link-box', (event) => {
            const href = $(event.currentTarget).data('href');
            location.href = href;
        });

        // Enterキーで入力にする
        $(document).on('keypress', 'input:not(.allow-submit), select:not(.allow-submit)', (event) => {
            return event.which != 13
        });

        // 数値入力にフォーカスすると全選択
        $(document).on('focus', 'input[type="number"]', (event) => {
            $(event.currentTarget).select();
        });

        /**
         * pagination, sortはremoteで実行するが、URLにパラメータを記載する
         */
        $(document).on("click", ".ata-pagination .page-item a", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            let $list = $($(event.target).closest('.pagination-link').data("list"));
            let modelName = $list.data("model");
            atokyo3.shared.setPaginationParams(historyParams, modelName, $(event.target));
            //
            console.log(historyURL.toString());
            history.pushState(null, null, historyURL.toString());
            let sortURL = new URL(window.location.origin + $list.data("sort-path"));
            atokyo3.shared.buildQuery(sortURL, historyParams);
            $.ajax({
                dataType: 'script',
                url: sortURL.toString(),
                success: ()  => {}
            })
            return false;
        });

        $(document).on('click', '.nav .ata-tab-link', function(event) {
            console.log('paginationSort is undefined');
            let url = new URL(window.location.origin + $(event.target).attr("href"));
            //console.log(url.toString());
            history.pushState(null, null, url.toString());
            return true;
        });


//       テーブルヘッダーを使ったテーブルソート機能
        $(document).on("click", ".ata-table-sortable .sort-button", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let $column = $(event.target).closest(".sort-column");
            let $table = $(event.target).closest(".ata-table-sortable");
            let modelName = $table.data("model");
            let sortURL = new URL(window.location.origin + $table.data("sort-path"));
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            atokyo3.shared.setSortParams(historyParams, modelName, $column);
            atokyo3.shared.buildQuery(sortURL, historyParams);
            history.pushState(null, null, historyURL.toString());
            $.ajax({
                dataType: 'script',
                url: sortURL.toString(),
                success: ()  => {}
            })
            return false;
        });


//        検索機能でのURL作成
        $(document).on("click", ".ata-search-form .button-search", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let $inputText = $(event.target).closest('.ata-search-form').find('.field-query');
            let inputValue = $inputText.val();
            let $table = $($(event.target).closest('.ata-search-form').data("table"));
            let modelName = $table.data("model");
            let sortURL = new URL(window.location.origin + $table.data("sort-path"));
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            atokyo3.shared.setSearchParams(historyParams, modelName, inputValue);
            atokyo3.shared.buildQuery(sortURL, historyParams);
            history.pushState(null, null, historyURL.toString());
            //console.log("check" + url.toString());
            $.ajax({
                dataType: 'script',
                url: sortURL.toString(),
                success: ()  => {}
            })
            return false;
        });

        $(document).on("click", ".ata-table tr.ata-show-detail", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;

            let $tr = $(event.currentTarget);
            let $table = $tr.closest('.ata-table');
            let modelName = $table.data("model");

            if($tr.next('tr').hasClass('ata-row-detail')) {
                $tr.next('tr.ata-row-detail').find('div.detail-container').slideUp(200, function(){
                    $tr.next('tr.ata-row-detail').remove();
                });
                atokyo3.shared.setDetailParams(historyParams, modelName, null);
                history.pushState(null, null, historyURL.toString());
                return;
            }
            $tr.closest('tbody').find('tr.ata-row-detail').remove();
            atokyo3.shared.setDetailParams(historyParams, modelName, $tr.data('id'));
            history.pushState(null, null, historyURL.toString());
            let detailURL = new URL(window.location.origin + $tr.data("detail-path"));
            $.ajax({
                dataType: 'script',
                url: detailURL.toString(),
                success: ()  => {}
            })
            return false;
        });

        /**
         * リストの詳細表示
         */
        $(document).on("click", ".ata-list li.ata-show-detail", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;

            let $li = $(event.currentTarget);
            let $list = $li.closest('.ata-list');
            let modelName = $list.data("model");

            if($li.next('li').hasClass('ata-row-detail')) {
                $li.next('li.ata-row-detail').find('div.detail-container').slideUp(200, function(){
                    $li.next('li.ata-row-detail').remove();
                });
                atokyo3.shared.setDetailParams(historyParams, modelName, null);
                history.pushState(null, null, historyURL.toString());
                return;
            }
            $li.closest('ul').find('li.ata-row-detail').remove();
            atokyo3.shared.setDetailParams(historyParams, modelName, $li.data('id'));
            history.pushState(null, null, historyURL.toString());
            let detailURL = new URL(window.location.origin + $li.data("detail-path"));
            $.ajax({
                dataType: 'script',
                url: detailURL.toString(),
                success: ()  => {}
            })
            return false;
        });

        /**
         * has_many 子フォーム追加・削除
         */
         $(document).on("click", ".ata-child-container .ata-child-button .ata-button-add", (event) => {
             event.stopImmediatePropagation();
             event.preventDefault();
             let $target = $(event.target);
             let $container = $target.closest('.ata-child-container');
             let $template = $container.find('.ata-child-template');
             let $list = $container.find('.ata-child-list');
             let nextIndex = $list.find('.ata-child-field').length
             //テンプレートはindex:9999で作成しているので正しいindexに書き換える
             let htmlText = $template.html().replace(/9999/g, nextIndex.toString()).trim();
             let $clone = $($.parseHTML(htmlText)[0]);
             $clone.find('input, select, textarea').prop('disabled', false);
             $list.append($clone);
             $target.trigger('ata.child.added');
             return false;
         });
        $(document).on("click", ".ata-child-container .ata-child-button .ata-button-delete", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let $target = $(event.target);
            let $field = $target.closest('.ata-child-field');
            let $list = $field.closest('.ata-child-list');
            let index = $list.find('.ata-child-field').index($field);
            $target.trigger('ata.child.deleted',[index]);
            $field.remove();
            return false;
        });
    }


    /**
     * スピナー登録
     * @param $target
     */
    static init_spinner($target) {
        console.log('init_spinner click');
        //let $button = $(this)
        $target.find('.fa-spin').show();
        $target.find('.pre-spin').hide();
        setTimeout(() => {
            $target.addClass('disabled').prop('disabled', true);
        },10);
    }

    /**
     * スピナー終了
     * @param $base
     */
    static end_spinner($base) {
        console.log('end_spinner');
        if ($('.ata-button-spinner').length > 0) {
            //button-spin-for-modal
            console.log('end_spinner start');
            let $button;
            if($base) {
                $button = $base.find('.ata-button-spinner');
            }
            else {
                $button = $('.ata-button-spinner');
            }
            if($button.find('.fa-spin').css('display') !== 'none') {
               $button.find('.fa-spin').hide();
               $button.find('.pre-spin').show();
               $button.removeClass('disabled').prop('disabled', false);
            }
        }
    }

    /**
     * 全エラーラベルクリア
     * @param $base
     */
    static clean_validation_errors($base) {
        $base.find('.ata-field').removeClass('error')
        $base.find('.error-message').text('');
    }

    /**
     * エラーラベル表示
     * @param $target
     * @param message
     */
    static show_validation_errors($target, message) {
        $target.addClass('error')
        $target.find('.error-message').append($('<div>').text(message));
    }

    static hide_validation_errors($target) {
        $target.removeClass('error')
        $target.find('.error-message').text('');
    }

    static hide_flash_message() {
        setTimeout(() => {
            $('#flash-message-container').hide();
        },2000);
    }

    static show_flash_message() {
        if($('#flash-message-container .alert-success').length > 0)
        {
            $('#flash-message-container').slideDown(1000, this.hide_flash_message);
        }
        else if($('#flash-message-container .alert-danger').length > 0)
        {
            $('#flash-message-container').slideDown(1000);
            $('#flash-message-container .alert-danger .button-close').on('click', function() {
                $('#flash-message-container').hide();
            });
        }
    }
    static show_error(message) {
        $('#flash-message-container').html($('<div>').addClass('alert alert-danger').text(message)[0]);
        atokyo3.shared.show_flash_message();
    }

    static numberWithCommas(x) {
        if(x == null)
            return '-'
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * 不正なデータは「0」を返す
     * @param x
     * @returns {number}
     */
    static revertNumberWithCommas(x) {
        if(x == null)
            return 0
        return parseInt(x.toString().replace(",", "")) || 0;
    }

    static searchAddress(prefix) {
        $('.button-zip-to-address').on('click', function() {
            if($(this).closest('.form-address').find('textarea[name="' +  prefix + '[address]' + '"]').val().length > 0) {
                if(confirm("translation missing: en.message.alert.address_over_write")){
                    AjaxZip3.zip2addr(prefix + '[zip_code]', '', prefix + '[prefecture_id]', prefix + '[address]');
                }
            }
            else {
                AjaxZip3.zip2addr(prefix + '[zip_code]', '', prefix + '[prefecture_id]', prefix + '[address]');
            }
            AjaxZip3.onSuccess = function() {
                $('.address-address').focus();
                $('.transportation-trigger').trigger('change');
            };
            AjaxZip3.onFailure = function() {
                alert("translation missing: en.message.alert.address_not_found");
            };
            return false;
        });
    }

    static uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static setSortParams(params, modelName, $column) {
        params.set(`${modelName}[sort_column]`, $column.data("column"));
        params.set(`${modelName}[order_type]`, $column.data("order"));
    }

    static setPaginationParams(params, modelName, $link) {
        let paginationURL = new URL(window.location.origin + $link.attr('href'));
        let paginationParams = paginationURL.searchParams;
        params.set(`${modelName}[page]`, paginationParams.get(`${modelName}[page]`));
    }

    static setSearchParams(params, modelName, $inputValue) {
        params.set(`${modelName}[query]`, $inputValue);
//        ページ数だけリフレッシュ
        params.set(`${modelName}[page]`, '');
    }

    static setDetailParams(params, modelName, id) {
        if(id){
            params.set(`${modelName}[id]`, id);
        } else {
            params.delete(`${modelName}[id]`);
        }
    }

    static buildQuery(url, params) {
        for (let [key, value] of params) {
            console.log(key, value);
            url.searchParams.append(key, value);
        }
    }

    // フェア選択機能
    static selectFair() {
        $('#fair-select').on('change', (event) => {
            $(event.target).parents('form').submit();
            return false;
        });
    }

    // ファイル選択追加機能
    static addFileField() {
        $('#ata-add-file-field').on('click', () => {
            let fileField = $('.file-field');
            let dummyFileField = $('.dummy-file-field');
            dummyFileField.children().clone().appendTo(fileField);
            return false;
        });
    }

    // ファイル削除機能
    static deleteFile() {
        $('.ata-file-delete-btn').on('click', (event) => {
            let fileBox = $(event.target).parents('.file-box');
            fileBox.find('.delete-file-ids').prop('disabled', false);
            fileBox.find('.filename').css('text-decoration','line-through');
            return false;
        });
    }


    /* 文字数カウント機能 */
    static countChars() {
        $(document).on('change keyup mouseup input', '.count-chars-ja', (event) => {
            let $target = $(event.currentTarget);
            let formGroup = $target.closest('.form-group');

            if(formGroup.find('.count-char-1byte').length){
                setText(1);
            }
            if(formGroup.find('.count-char-2byte').length){
                setText(2);
            }

            function setText(byte) {
                let count = formGroup.find(`.count-char-${byte}byte`);
                let countLabel = formGroup.find(`.count-char-${byte}byte-label`);
                let limit = count.data('limit');
                let remainingCount = remainingCharCount($target.val(), limit, byte);
                count.text(remainingCount);
                if (remainingCount < 0) {
                    countLabel.css('color', '#ff0000');
                    count.css('color', '#ff0000');
                    $target.addClass('bg-over');
                } else {
                    countLabel.css('color', '#000000');
                    count.css('color', '#000000');
                    $target.removeClass('bg-over');
                }
            }
        });

        $(document).on('change keyup mouseup input', '.count-chars-en', (event) => {
            let $target = $(event.currentTarget);
            let formGroup = $target.closest('.form-group');
            let count = formGroup.find('.count-word');
            let countLabel = formGroup.find('.count-word-label');
            let limit = count.data("limit");
            let remainingCount = remainingWordCount($target.val(), limit);
            count.text(remainingCount);
            if (remainingCount < 0) {
                countLabel.css('color', '#ff0000');
                count.css('color', '#ff0000');
                $target.addClass('bg-over');
            } else {
                countLabel.css('color', '#000000');
                count.css('color', '#000000');
                $target.removeClass('bg-over');
            }
        });

        function remainingCharCount(chars, limit, byte) {
            let count = charCount(chars);
            let remainingCount = Math.floor((limit - count) / byte);
            return remainingCount;
        }

        function remainingWordCount(chars, limit) {
            let count = wordCount(chars)
            let remainingCount = limit - count;
            return remainingCount;
        }

        function charCount(str) {
            let len = 0;
            let i = 0;
            let str3, str6, check3, check6;
            str = escape(str);
            while(i < str.length) {
                str3 = str.substring(i, i+3);
                str6 = str.substring(i, i+6);
                check3 = str3.match(/^%[0-9a-f]{2}$/i);
                check6 = str6.match(/^%u[0-9a-f]{4}$/i);
                if (check3) { //半角特殊文字
                    i += 2;
                }
                else if(check6) { //全角文字
                    i += 5;
                    len++;
                }
                i++;
                len++;
            }
            return len;
        }

        function wordCount(str) {
            let len = str.replace(/(\,|\.|:|;|\!|\?|\s)+/g, " ").split(" ").length - 1;
            return len;
        }

        $('.count-chars-ja').trigger('change');
        $('.count-chars-en').trigger('change');
    }


    /* フォーム並び替え機能 */
    static changeOrder() {
        $(document).on('click', '.ata-child-container .ata-child-button .ata-button-up', (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let self = $(event.target).closest('div.ata-child-field');
            let target = self.prev('div.ata-child-field');
            if (target.length > 0) {
                target.before(self);
            }
            else {
                self.nextAll('div.ata-child-field:last').after(self);
            }
            return false;
        });

        $(document).on('click', '.ata-child-container .ata-child-button .ata-button-down', (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let self = $(event.target).closest('div.ata-child-field');
            let target = self.next('div.ata-child-field');
            if (target.length > 0) {
                target.after(self);
            }
            else {
                self.prevAll('div.ata-child-field:last').before(self);
            }
            return false;
        });
    }
}
module.exports = Shared
